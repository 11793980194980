import Vue from 'vue'
import './element-variables.scss'
import ElementUI from 'element-ui'
import App from './App.vue'
import i18n from './i18n/i18n'
import store from './vuex/store'
import router from './router/index'
import vueConfig from './vue-config'
import Http from './utils/http'
import Common from './utils/common'
import VueCookies from 'vue-cookies'
import request_url from './request_url.js'
import VueFileUpload from 'vue-file-upload';
import ws from './utils/websocket'
import systemSettings from './utils/systemSettings'
import userCenterMethods from './utils/userCenterMethods'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'//这里注意具体看使用的版本是否需要引入样式，以及具体位置。
import scroll from 'vue-seamless-scroll'
import VueAnalytics from 'vue-analytics'
Vue.use(scroll);
Vue.use(VueAwesomeSwiper, /* { default global options } */)
Vue.use(ElementUI)
Vue.use(vueConfig)
Vue.use(userCenterMethods)
Vue.use(systemSettings)
Vue.use(VueCookies)
Vue.use(Common)
Vue.use(Http)
Vue.use(ws, request_url.websocket_ws, {store: store})
//固定值
Vue.setValueByKey('cas_path', request_url.cas_url)
Vue.setValueByKey('vue_cms_path', request_url.vue_cms_url)
Vue.setValueByKey('otc_path', request_url.otc_url)
Vue.setValueByKey('trading_path', request_url.trading_url)
Vue.setValueByKey('www_path', request_url.www_url)
Vue.setValueByKey('api_path', request_url.api_url)
Vue.setValueByKey('admin_cms_path', request_url.admin_cms_url)

Vue.setValueByKey('cdn_path', request_url.cdn_url)

Vue.config.productionTip = false

Vue.component('Vue', () => Vue)
Vue.use(VueAnalytics, {
  id: 'UA-166956644-1',
  router
});
new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
