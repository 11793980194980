module.exports = {
  website_name: "GDA",
  white_logo: 'https://showmethemoney.fuxinsci.com/image_after_2019_06/navc_logo_light@2x.png',
  black_logo: 'https://showmethemoney.fuxinsci.com/image_after_2019_06/navc_logo_light@2x.png',
  android_download_url: '',
  android_download_qrcode: '',
  ios_download_url: '',
  ios_download_qrcode: '',
  my_assets_banner: '',
}
