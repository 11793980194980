<template>
	<div style="background-image: var(--home-footer);">
		<div class="footer_home" :style="{ 'margin-top': (this.$route.path == '/' ? '' : '100px') }">
			<div class="box_left">
				<div class="logo_img">
					<img :src="logo" height="46">
				</div>
				<div class="trand">
					<a href="https://twitter.com/" rel="noopener noreferrer" target="_blank" style="color: #47426e;"><i
							class="iconfont icon-twitter" style="font-size: 22px"></i></a>
					<a href="https://t.me/" rel="noopener noreferrer" target="_blank" style="color: #47426e;"><i
							class="iconfont icon-TelegramEn" style="font-size: 19px"></i></a>
					<a href="https://www.facebook.com/" rel="noopener noreferrer" target="_blank"
						style="color: #47426e;"><i class="iconfont icon-facebookfacebook52" style="font-size: 19px"></i>
					</a>
				</div>
				<div class="footer_text">
					Copyright © 2024 {{ website_name }} All Rights Reserved
				</div>
			</div>
			<div class="box_right">
				<div class="on">
					<div style="color: #696584;font-size: 14px;margin-bottom: 3px">{{ $t('on') }}</div>
					<div><a :href="about_coiex" target="_blank">{{ $t('about') }}{{ currentHomeText }}</a></div>
					<div><a :href="vue_cms_url" target="_blank">{{ $t('announcement_center') }}</a></div>
					<div><a :href="contact_us" target="_blank">{{ $t('contact_us') }}</a></div>
					<div><a :href="job_career" target="_blank">{{ $t('career_opportunities') }}</a></div>
				</div>
				<div class="on">
					<div style="color: #696584;font-size: 14px;margin-bottom: 3px">{{ $t('service') }}</div>
					<div><a :href="new_exchange" target="_blank">{{ $t('apply_for_listing') }}</a></div>
					<div><a href="javascript:;" @click="go_digital_assets()">{{ $t('asset_introduction') }}</a></div>
					<div><a href="javascript:;" @click="go_currency_fee()">{{ $t('rate_standard') }}</a></div>
					<!-- <div><a href="javascript:;" >{{$t('api_documentation')}}</a></div> -->
				</div>
				<div class="terms">
					<div style="color: #696584;font-size: 14px;margin-bottom: 3px">{{ $t('terms') }}</div>
					<div><a :href="privacy_policy" target="_blank">{{ $t('privacy_policy') }}</a></div>
					<div><a :href="legal_anouncement" target="_blank">{{ $t('legal_notices') }}</a></div>
					<div><a :href="prevent_fishing" target="_blank">{{ $t('prevent_phishing_attacks') }}</a></div>
					<div><a :href="prevent_virus" target="_blank">{{ $t('prevent_virus_trojans') }}</a></div>
				</div>
				<div class="help">
					<div style="color: #696584;font-size: 14px;margin-bottom: 3px">{{ $t('help') }}</div>
					<div><a :href="how_to_register" target="_blank">{{ $t('how_to_register') }}</a></div>
					<div><a :href="retrieve_password" target="_blank">{{ $t('retrieve_password') }}</a></div>
					<div><a :href="how_to_crypto_crypto_trade" target="_blank">{{ $t('how_to_trade') }}</a></div>
					<div><a :href="how_to_deposit_crypto" target="_blank">{{ $t('how_to_charge_coins') }}</a></div>
				</div>
			</div>
		</div>
	</div>
</template>
<style type="text/css">
.on {
	float: left;
}

.on div {
	margin-top: 5px;
}

.terms div {
	margin-top: 5px;
}

.help div {
	margin-top: 5px;
}

.on div a {
	color: #f8f7ff;
}

.terms div a {
	color: #f8f7ff;
}

.help div a {
	color: #f8f7ff;
}

/*.help{
		float: right;
		margin-left: 70px;
	}
	.terms{
		float: left;
		margin-left: 70px;
	}*/
.footer_home {
	display: flex;
	justify-content: space-between;
	padding-bottom: 20px;
}

.box_left {
	color: #ffffff;
}

.box_right {
	color: #ffffff;
	display: flex;
	justify-content: space-between;
	margin-top: 170px;
	margin-right: 100px;
	width: 40%;
}

.logo_img {
	margin-top: 59px;
	margin-left: 68px;
}

.trand {
	margin-top: 35px;
	margin-left: 53px;
}

.trand a {
	margin-left: 15px;
}

.footer_text {
	margin-top: 110px;
	margin-left: 68px;
	color: #696584;
}
</style>
<script type="text/javascript">
import Vue from 'vue'
import { white_logo, website_name } from '../application'
export default {
	name: 'Footer',
	props: {
		// 基础的类型检查 (`null` 和 `undefined` 会通过任何类型验证)
		language: String
	},
	data() {
		return {
			vue_cms_url: '',
			logo: white_logo,
			website_name: website_name,
			about_coiex: Vue.getValueByKey('vue_cms_path') + '/home_article_detail/NzY=?lang=' + this.language,
			contact_us: Vue.getValueByKey('vue_cms_path') + '/home_article_detail/MTE=?lang=' + this.language,
			job_career: Vue.getValueByKey('vue_cms_path') + '/home_article_detail/MTY4?lang=' + this.language,
			new_exchange: Vue.getValueByKey('vue_cms_path') + '/home_article_detail/MTM==?lang=' + this.language,
			privacy_policy: Vue.getValueByKey('vue_cms_path') + '/home_article_detail/NjA=?lang=' + this.language,
			legal_anouncement: Vue.getValueByKey('vue_cms_path') + '/home_article_detail/ODE=?lang=' + this.language,
			prevent_fishing: Vue.getValueByKey('vue_cms_path') + '/home_article_detail/Ng==?lang=' + this.language,
			prevent_virus: Vue.getValueByKey('vue_cms_path') + '/home_article_detail/Nw==?lang=' + this.language,
			how_to_register: Vue.getValueByKey('vue_cms_path') + '/home_article_detail/Mg==?lang=' + this.language,
			retrieve_password: Vue.getValueByKey('vue_cms_path') + '/home_article_detail/Mw==?lang=' + this.language,
			how_to_crypto_crypto_trade: Vue.getValueByKey('vue_cms_path') + '/home_article_detail/NA==?lang=' + this.language,
			how_to_deposit_crypto: Vue.getValueByKey('vue_cms_path') + '/home_article_detail/NQ==?lang=' + this.language
		}
	},
	mounted() {
		this.vue_cms_url = Vue.getValueByKey('vue_cms_path') + '?lang=' + this.currentLang;
	},
	methods: {
		go_digital_assets() {
			this.$router.push({ path: '/asset_introduction' }).catch(data => { })
		},
		go_currency_fee() {
			this.$router.push({ path: '/currency_fee' }).catch(data => { })
		}
	},
}
</script>
