<template>
<div style="background-color: var(--header-bgcolor);border-bottom: 1px solid var(--header-buttom-bodercolor);min-width: var(--page-content-width);">
  <div class="layouts-header-container" style="height: 60px;margin: 0 auto;">
      <div class="layouts-header-menu layouts-header-left" style="">
        <router-link to="/" class="layouts-header-logo" style="display: flex; align-items: center;">
          <img style="height: 32px;" :src="logo_url" />
        </router-link>
        
        <div v-if="isNotLoggedIn || currentUserInfo.is_able_to_otc">
          <a target="_blank" rel="noopener noreferrer nofollow" :href="otc_url" class="layouts-header-mainItem">
            {{$t("currency_trading")}}
          </a>
        </div>
        <div v-if="isLoggedIn && !currentUserInfo.is_able_to_otc">
          <a target="_blank" rel="noopener noreferrer nofollow" @click="dont_alow_otc()" class="layouts-header-mainItem">
            {{$t("currency_trading")}}
          </a>
        </div>
        <a target="_blank" class="layouts-header-mainItem ant-dropdown-trigger" :href="trading_url">
          {{$t("currency_transaction")}}
        </a>
        <!--
        <a target="_blank" class="layouts-header-mainItem ant-dropdown-trigger" @click="salesPromotionVue">
          {{$t("sales_promotion")}}
        </a>
        -->
        <a class="layouts-header-mainItem ant-dropdown-trigger" href="javascript:;"style="color: #f7f6ff">
          <el-dropdown>
            <span>
              {{$t("announcement_center")}}
            </span>
            <el-dropdown-menu slot="dropdown" style="margin-top: -5px">
              <el-dropdown-item  v-for="item in announcements">
                <div class="announcement" @click="go_cms_article_detail(item.id)">
                  <span>
                    {{item.title}}
                  </span>
                  <span>
                    {{item.create_time}}
                  </span>
                </div>
              </el-dropdown-item>
              <el-dropdown-item divided>
                <a target="_blank" class="announcement" :href="vue_cms_url">
                  <span>
                    {{$t("more")}}
                  </span>
                  <span>
                  </span>
                </a>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </a>
      </div>
      <div class="layouts-header-menu layouts-header-right">
        <div @click="switchTheme()" class="layouts-header-themeControl" style="margin-right: 30px;">
          <i class="iconfont icon-dengpao">
          </i>
        </div>
        <!-- <a class="layouts-header-mainItem ant-dropdown-trigger">
          <el-dropdown>
            <span>
              {{$t("tool")}}
            </span>
            <el-dropdown-menu slot="dropdown" style="margin-top: -6px">
              <el-dropdown-item  @click.native="go_digital_assets()">{{$t("introduction_to_digital_assets")}}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </a> -->
        <a class="layouts-header-mainItem ant-dropdown-trigger" v-show="is_login">
          <el-dropdown>
            <span>
              {{$t("assets")}}
            </span>
            <el-dropdown-menu slot="dropdown" style="margin-top: -6px">
              <el-dropdown-item  @click.native="go_assets()">{{$t("my_assets")}}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </a>
        <a class="layouts-header-mainItem components-selectlang-index-lang ant-dropdown-trigger">
          <i class="iconfont icon-diqiu" style="font-size: 18px;margin-bottom: 3px"></i>
          <el-dropdown style="margin-left: 3px;">
            <span v-html="language_name">
              简体中文
            </span>
            <el-dropdown-menu slot="dropdown" style="margin-top: -6px">
              <el-dropdown-item v-for="item in languageData">
                <div class='lang' @click="changeLanguage(item.label)">
                  <span v-bind:class="[item.label == lang ? 'selected' : null ]" >
                  </span>
                  <span>{{item.name}}</span>
                </div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </a>
        <div @click="go_message()" class="message" style="cursor:pointer;">
          <img class="message-bell" src="https://showmethemoney.fuxinsci.com/image_after_2019_06/3213/message.png">
          <div style="color: #E02020; font-size: 43px; margin-top: -53px; margin-left: 2px;" v-if="isLoggedIn && currentHaveNewMessage">·</div>
        </div>
        <a class="layouts-header-mainItem ant-dropdown-trigger" style="position: relative;" @mouseenter="showUserCenter(isHideUserCenter)" @mouseleave="hideUserCenter(isHideUserCenter)" v-if="is_login">
          <i class="iconfont icon-geren" style="font-size: 18px;"></i>
          <div style="position: absolute;top: 44px; left: -135px; width: 100%;">
            <div>
                <div class="ant-dropdown  ant-dropdown-placement-bottomCenter  " v-bind:class="[isHideUserCenter ? 'ant-dropdown-hidden' : '' ]"
                    style="left: 0px; top: 0px;">
                    <ul class="ant-dropdown-menu layouts-header-userMenu ant-dropdown-menu-light ant-dropdown-menu-root ant-dropdown-menu-vertical"
                        role="menu" tabindex="0" style="background: var(--user-hover-bgcolor)">
                        <li class="ant-dropdown-menu-item layouts-header-noHover" role="menuitem" style="color: #6c7197">
                            <span>{{username}}</span>
                        </li>
                        <li class=" ant-dropdown-menu-item-divider" style="background-color: var(--user-hover-bottom-bgcolor)"></li>
                        <li class="ant-dropdown-menu-item layouts-header-noHover" role="menuitem" style="line-height: 4px;margin-top: 18px;">
                            <div class="layouts-header-userPower" style="color: #6c7197;border-bottom:0px;justify-content: flex-start;margin-left: 12px;">
                              <p class="layouts-header-none" v-if="auth_status == 1" style="color: var(--features-make-color)">
                                <i class="iconfont icon-idcard" style="vertical-align: -1px;"></i> &nbsp;{{$t("verified")}}
                              </p>
                              <p class="layouts-header-none" @click="go_auth" v-if="auth_status == 2" style="color: #a5a5ba">
                                <i class="iconfont icon-idcard" style="vertical-align: -1px;"></i> &nbsp;{{$t("not_certified")}}
                              </p>
                              <p class="layouts-header-none" v-if="auth_status == 3" style="color: #a5a5ba">
                                <i class="iconfont icon-idcard" style="vertical-align: -1px;"></i> &nbsp;{{$t("under_review")}}
                              </p>
                              <p class="layouts-header-none" @click="go_auth" v-if="auth_status == 4" style="color: var(--features-no-make-color)">
                                <i class="iconfont icon-idcard" style="vertical-align: -1px;"></i> &nbsp;{{$t("authentication_failed")}}
                              </p>
                            </div>
                        </li>
                        <li class="ant-dropdown-menu-item hover_bg" role="menuitem" @click="clickCenter">
                          <router-link to="/user_center" style="color: #6c7197">{{$t("personal_center")}}</router-link>
                        </li>
                        <li class="ant-dropdown-menu-item hover_bg" role="menuitem"  @click="UserProtocol" style="color: #6c7197" v-if="currentProtocolForUsers">
                          <span style="margin-left: 15px">
                            {{$t('subscription_agreement')}}
                          </span>
                        </li>
                        <li class=" ant-dropdown-menu-item-divider" style="background-color: var(--user-hover-bottom-bgcolor)"></li>
                        <li class="ant-dropdown-menu-item hover_bg" role="menuitem"  @click="signOut" style="color: #6c7197">
                          <span>
                            <i class="iconfont icon-tuichu1" style="font-size: 14px; margin-right: 5px;"></i>
                            <span>
                              {{$t("exit_account")}}
                            </span>
                          </span>
                        </li>
                    </ul>
                </div>
              </div>
           </div>{{username}}
        </a>
        <a :href="loginUrl" v-if="!is_login">
          <button type="button" class="ant-btn ant-btn-primary" style="margin-right: 10px;">
            <span>
                {{$t("login")}}
            </span>
          </button>
        </a>
        <a :href="signUrl" v-if="!is_login">
          <button type="button" class="ant-btn" style="margin-right: 30px;">
            <span>
              {{$t("registered")}}
            </span>
          </button>
        </a>
      </div>
    </div>
    <user-protocol ></user-protocol>
</div>
</template>
<style type="text/css" scoped>
  .hover_bg:hover{
    background-color: var(--hoverline-list-bgcolor);
  }
  .message-bell{
    height: 16px;
    width: 16px;
    margin-bottom: 4px;
    filter: var(--filter-invert);
  }
  .message{
    width:16px;
    height:16px;
    margin-right: 26px;
    margin-left: 36px;
    background-color: rgba(0, 0, 0, 0);
  }
</style>
<script>
import UserProtocol from './UserProtocol'
import { white_logo, black_logo } from '../application'
import Vue from 'vue'
export default {
  name: 'NavHeader',
  data () {
    return {
      otc_url: '',
      trading_url: '',
      vue_cms_url: '',
      loginUrl: '',
      signUrl: '',
      isHideUserCenter:true,
      announcements: [],
      languageData:[
          {
            label:'zh',
            name:'简体中文'
          },
          {
            label:'en',
            name:'English'
          },
          {
            label:'ru',
            name:'русский'
          },
          {
            label:'jp',
            name:'日本語'
          },
          {
            label:'kr',
            name:'한국어'
          }
      ]
    }
  },
  components: {
    UserProtocol
  },
  mounted() {
      this.loginUrl = Vue.getValueByKey('cas_path')+'/sign_in?service=' + window.location.href.split('?')[0] + '&lang=' + this.currentLang;
      this.signUrl = Vue.getValueByKey('cas_path')+'/sign_up?service=' + window.location.href.split('?')[0] + '&lang=' + this.currentLang;
      this.otc_url = Vue.getValueByKey('otc_path') + '?lang=' + this.currentLang;
      this.trading_url = Vue.getValueByKey('trading_path') + '?lang=' + this.currentLang;
      this.vue_cms_url = Vue.getValueByKey('vue_cms_path') + '?lang=' + this.currentLang;
      this.getUrl('/help_center/recent_announcements', {
          lang:this.currentLang
      }).then((response) => {
          if(response.data.code == 20000){
            this.announcements = response.data.dataResult;
          }
      });
  },
  computed: {
    auth_status: function () {
        if(this.isLoggedIn){
            return this.currentUserInfo.auth_status
        }else{
            return false;
        }
    },
    is_login: function () {
      if(this.isLoggedIn){
        return true;
      }else{
        return false;
      }
    },
    username: function () {
      if(this.isLoggedIn){
        return this.currentUserInfo.nick_name
      }else{
        return '';
      }
    },
    logo_url: function () {
      if(this.currentTheme == 'light'){
        return black_logo;
      }else{
        return white_logo;
      }
    },
    lang: function () {
      return this.currentLang;
    },
    language_name: function(){
      if(this.currentLang == 'zh'){
          return "简体中文"
      }else if(this.currentLang == 'en'){
          return "English"
      }else if(this.currentLang == 'ru'){
          return "русский"
      }else if(this.currentLang == 'jp'){
          return "日本語"
      }else if(this.currentLang == 'kr'){
          return "한국어"
      }else{
          return "English";
      }
    }
  },
  methods: {
    dont_alow_otc(){
      this.$message({
        message: this.$t('is_not_allowed'),
        type: 'error'
      })
      return
    },
    go_message(){
      if (!this.currentJudgeIsLoggedIn) {
        location.href = Vue.getValueByKey('cas_path')+'/sign_in?service=' + window.location.href.split('?')[0] + '&lang=' + this.currentLang;
      }
      this.$router.push({path:'/message'}).catch(data => {  })
    },
    salesPromotionVue(){
      this.$router.push({ path: ('/sales_promotion')});
    },
    clickCenter(){
      this.isHideUserCenter = true;
    },
    go_cms_article_detail(id){
      window.open(Vue.getValueByKey('vue_cms_path') + '/home_article_detail/' + id+ '?lang=' + this.currentLang,"_blank");
    },
    go_auth(){
      this.$router.push({path:'/identity_auth'}).catch(data => {  })
    },
    go_digital_assets(){
      this.$router.push({path:'/asset_introduction'}).catch(data => {  })
    },
    go_assets(){
      this.$router.push({path:'/my_assets'}).catch(data => {  })
    },
    showUserCenter (){
      this.isHideUserCenter = false;
    },
    hideUserCenter (){
      this.isHideUserCenter = true;
    },
    changeLanguage (language) {
      Vue.setStorageVariable({'lang':language});
      location.reload();
    },
    switchTheme () {
      Vue.setStorageVariable({'theme': this.currentTheme === 'dark' ? 'light' : 'dark'})
    }
  }
}
</script>
<style lang="scss" scoped>
/* dropdown组件导致的颜色不同修复 */
.layouts-header-mainItem.ant-dropdown-trigger .el-dropdown {
  outline: none;
  display: flex;
  align-items: center;
  height: 100%;
  font-size: 16px;
  transition: color .3s;
  color: var(--header-color);
}
.layouts-header-mainItem.ant-dropdown-trigger .el-dropdown:hover {
  color: var(--active-color);
}
.announcement {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 300px;
    span:first-child {
      text-align: left;
      width: 250px;
      overflow: hidden;
      /*文本不会换行*/
      white-space: nowrap;
      /*当文本溢出包含元素时，以省略号表示超出的文本*/
      text-overflow: ellipsis;
    }
    span:last-child {
      text-align: right;
      width: 50px;
    }
}
.lang {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100px;
    span:first-child {
      position: relative;
      width: 15px
    }
    span.selected::after {
      content: ' ';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 10px;
      height: 10px;
      border-radius: 5px;
      background-color: var(--active-color);
    }
    span:last-child {
      width: 85px
    }
}
</style>
